<template>
  <div class="publishDes" style="margin: 50px 28px 20px">
    <span>发布人：{{ payInfo.name }}</span>
    <span>缴费单编号：{{ payInfo.payment_id }}</span>
    <span>发布时间：{{ payInfo.create_time }}</span>
    <span v-if="payType > 1">缴费时间：{{ payInfo.update_time }}</span>
  </div>

  <div class="paymentDetail">
    <van-cell-group inset>
      <van-cell :value="payInfo.title" />
      <van-cell
        v-for="inf in payInfo.item"
        :key="inf.title"
        :title="inf.title"
        :value="'¥' + inf.price"
      />
      <van-cell title="合计:" :value="'¥' + payInfo.price" />
    </van-cell-group>
  </div>
  <div v-if="payType == 1" style="margin: 60px 16px">
    <van-button round block type="primary" @click="showPopup">
      立即缴费
    </van-button>
  </div>

  <van-popup
    v-model:show="show"
    closeable
    position="bottom"
    :style="{ height: '50%' }"
  >
    <div style="margin-top: 20px">
      <van-cell :value="payInfo.school_name" />
      <van-cell title="缴费单编号" :value="payInfo.payment_id" />
      <van-cell title="缴费金额" :value="'¥' + payInfo.price" />
    </div>

    <div style="margin: 50px 16px">
      <van-button round block type="primary" @click="toBankpPay">
        确认付款
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import api from "../service/api";

export default {
  setup() {
    const show = ref(false);
    const payType = ref(1);
    const Route = useRoute(); //获取值时使用
    const router = useRouter(); //获取值时使用

    const payInfo = reactive({
      item: [],
      payment_id: "",
      create_time: "",
      update_time: "",
      child_id: "",
      school_id: "",
      school_name: "",
      title: "",
      name: "",
      price: "",
      bank_type: "",
    });
    const onClickLeft = () => {
      router.go(-1);
    };
    const showPopup = () => {
      //判断订单是否使用云闪付支付方式
      if(payInfo.bank_type != 8 ){// 8 银联支付
          Toast("请使用其他支付方式支付");
          return;
      }
      show.value = true;
    };
    const toBankpPay = () => {
      show.value = false;
      uniSubscriptionPay();
    };

    onMounted(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
       upsdk.setNavigationBarTitle({
          title: "缴费详情",
        });
        upsdk.setTitleStyle({
          backBtnVisible: "1",
          navBackgroundColor: "0xff1989fa",
        });
      });
      payType.value = Route.query.payType;
      onLoad();
    });

    const uniSubscriptionPay = () => {
      api
        .createUnionPayOrder({
          school_id: payInfo.school_id,
          orderNo:Route.query.payment_id,
          money: payInfo.price,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == 2000) {
            var appPayRequest = res.data.result.appPayRequest;
            var tn = appPayRequest.tn;
            /* eslint-disable */
            upsdk.pluginReady(function () {
              upsdk.pay({
                tn: tn,
                success: function () {
                   router.go(-1);
                },
                fail: function (err) {
                  router.go(-1);
                  Toast("支付失败");
                },
              });
            });
          } else  {
            router.go(-1);
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };
    const onLoad = () => {
      api
        .getPaymentDetail({
          payment_id: Route.query.payment_id,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == 34002) {
            payInfo.payment_id = res.data.result.payment_id;
            payInfo.name = res.data.result.name;
            payInfo.create_time = res.data.result.create_time;
            payInfo.update_time = res.data.result.update_time;
            payInfo.child_id = res.data.result.child_id;
            payInfo.school_id = res.data.result.school_id;
            payInfo.title = res.data.result.title;
            payInfo.price = res.data.result.price;
            payInfo.bank_type = res.data.result.bank_type;
            payInfo.item = res.data.result.item;
            payInfo.school_name = "付款给：" + res.data.result.school_name;
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    return {
      show,
      payType,
      payInfo,
      showPopup,
      toBankpPay,
      onClickLeft,
    };
  },
};
</script>

<style lang="less">
.publishDes {
  display: block;
  margin-left: 10px;
  line-height: 20px;
  font-size: 14px;
  span {
    display: block;
    font-size: 14px;
    padding: 2px 0;
    text-align: left;
  }
}
</style>
